/*
    redirectPost()
    -------------
    example usage:
        var redirect = 'http://www.website.com/page?id=23231';
        $.redirectPost(redirect, {x: 'example', y: 'abc'});
    source:
        https://stackoverflow.com/a/23347763/1035365
 */

$.extend(
    {
        redirectPost: function(location, args, force_input_name)
        {
            var form = '';
            $.each( args, function( key, value ) {
                // Useful in some instances, e.g. bulk edit: We just want to send an array as the field name ids[]
                if(force_input_name != null) { key = force_input_name; }
                form += '<input type="hidden" name="'+key+'" value="'+value+'">';
            });
            $('<form action="' + location + '" method="POST">' + form + '</form>').appendTo($(document.body)).submit();
        }
    });