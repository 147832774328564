var SC = {};

SC = {
	Ui : {
		toggleExpand : function(selector, collapse, expand) {
			$(selector).toggle(function() {
				$(collapse).hide();
				$(expand).toggleClass("col-lg-9");
				$(expand).toggleClass("no-filters");
				$(selector).data({
					"hidden" : "yes",
					"manual" : "yes"
				});
			}, function() {
				$(collapse).show();
				$(expand).toggleClass("col-lg-9");
				$(expand).toggleClass("no-filters");
				$(selector).data({
					"hidden" : "no",
					"manual" : "yes"
				});
			});

		},
		tidyToggle : function(selector) {
			if (window.matchMedia('(max-width: 1200px)').matches
					& $(selector).data("manual") !== "yes"
					& $(selector).data("hidden") !== "yes") {
				$(selector).trigger("click");
				$(selector).data({
					"manual" : "no"
				});
			} else if (window.matchMedia('(min-width: 1200px)').matches
					& $(selector).data("manual") !== "yes"
					& $(selector).data("hidden") === "yes") {
				$(selector).trigger("click");
				$(selector).data({
					"manual" : "no"
				});
			} else {
			}
		}
	},
	Validate : {
		email : function(selector, init, failure) {
			init();
			var re = /^[-a-z0-9~!$%^&*_=+}{\'?]+(\.[-a-z0-9~!$%^&*_=+}{\'?]+)*@([a-z0-9_][-a-z0-9_]*(\.[-a-z0-9_]+)*\.(aero|arpa|biz|com|coop|edu|gov|info|int|mil|museum|name|net|org|pro|travel|mobi|[a-z][a-z])|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?$/i;
			var share_to = $(selector).val();
			var share_to_emails = share_to.split(",");
			var email_address = "";
			for (i = 0; i < share_to_emails.length; i++) {
				email_address = share_to_emails[i].trim();
				if (re.test(email_address) == false) {
					failure();
					return false;
				}
			}
			return true;
		}
	},
	PIM : {
		Product : {
			getSelectedItems : function(selector) {
				// to get selected items from browser
				ids = [];
				$(selector).each(function() {
					if ($(this).data("id") != undefined) {
						ids.push($(this).data("id"));
					}
				});
				return ids;
			},
			Bind : {
				modal : function(selector, init) {
					$(selector).on("click", function() {
						init();
					});
				}
			}
		}
	}, // later
	DAM : {
		Asset : {
			getRemoteAssets : function() {
				// to get remote json from server
			},
			getSelectedItems : function(selector) {
				// to get selected items from browser
				ids = [];
				$(selector).each(function() {
					if ($(this).data("id") != undefined) {
						ids.push($(this).data("id"));
					}
				});

				return ids;

			},
			Bind : {
				remote : function(selector, url, init, success) {
					$(selector).on("click", function() {
						init();
						$.post(url, {
							'id' : ids
						}, function(data) {
							if (data.success) {
								success();
							} else {
								showNoty("error", "An error occured.");

							}
						}).fail(function(xhr, ajaxOptions, thrownError) {
							showNoty("error", "An error occured.");
						});
					});
				},
				modal : function(selector, init) {
					$(selector).on("click", function() {
						init();
					});
				}
			}
		},
		JsTree : {
			getSelectedTreeNodes : function($selector) {
				ids = [];
				// e.g. "#select_location_directory_tree"
				$idsArray = $.jstree.reference($selector).get_selected();
				$.each($idsArray, function(i, v) {
					ids.push(v);
				});
				return ids;
			}
		},
		Tag : {
			getSelectedTags : function($selector) {
				ids = [];
				// e g. ".filter_tag input[type=checkbox]"
				$($selector).each(function() {
					if ($(this).prop('checked')) {
						ids.push($(this).attr("data-id"));
					}
				});
				return ids;

			},
			getSelectedTagsNames : function($selector) {
				names = [];
				// e g. ".filter_tag input[type=checkbox]"
				$($selector).each(function() {
					if ($(this).prop('checked')) {
						names.push($(this).attr("data-name"));
					}
				});
				return names;

			},
			getAllAsCheckBox : function(url, selector, callback,
					trash_icon_flag, type) {

				var checked = [];

				$(selector + ' input[type=checkbox]:checked').each(function() {
					//
					checked.push(this.id); // = this.id;
				})

				


				$
						.ajax({
							type : 'POST',
							url : url,
							data : {
								'type' : type
							},
							success : function(d) {
								var tagListHtml = '';
								$
										.each(
												d.data,
												function(i, v) {
													tagListHtml += '<div class="checkbox" style="margin:1px"><label class="filter_tag"><input type="checkbox" class="tag_checkbox"  id="tag_'
															+ v.id
															+ '" data-id="'
															+ v.id
															+ '" data-name="'
															+ v.name
															+ '"><span style="top:2px;position:relative">'
															+ v.name
															+ '</span></label>&nbsp;<span style="top: 2px; position: relative"  class="badge badge-info">'
															+ v.count
															+ '</span>';
													if (trash_icon_flag
															&& v.name != "untagged") {
														tagListHtml += '&nbsp;<a href="#" class="text-warning" data-name="'
																+ v.name
																+ '" data-id="'
																+ v.id
																+ '" data-target="#modal_trash_tag" data-toggle="modal" style="top: 2px; position: relative">Clear</a>';
													}
													tagListHtml += '</div>';
												});
								$(selector).html(tagListHtml);

								checked.forEach(function(value,index) {
									//
									$(selector + ' input#' + value).prop('checked', true);
								});

								callback();

							}
						});
			},
		}
	}
};

// TODO: Move into separate files
$(document).ready(function(){

  jQuery('img.svg').each(function(){
		var $img = jQuery(this);
		var imgID = $img.attr('id');
		var imgClass = $img.attr('class');
		var imgURL = $img.attr('src');

		jQuery.get(imgURL, function(data) {
			// Get the SVG tag, ignore the rest
			var $svg = jQuery(data).find('svg');

			// Add replaced image's ID to the new SVG
			if(typeof imgID !== 'undefined') {
				$svg = $svg.attr('id', imgID);
			}
			// Add replaced image's classes to the new SVG
			if(typeof imgClass !== 'undefined') {
				$svg = $svg.attr('class', imgClass+' replaced-svg');
			}

			// Remove any invalid XML tags as per http://validator.w3.org
			$svg = $svg.removeAttr('xmlns:a');

			$svg = $svg.attr('preserveAspectRatio','none');

			if($img.hasClass('svg-template')) {
				$svg = $svg.attr('viewBox',"0 0 210 297");
			}

			// Replace image with new SVG
			$img.replaceWith($svg);

		}, 'xml');

	});

	url = window.location.href;
	if(url.split('dam/storage/assets').length > 1) {
		$('.dam-assets').addClass('active');
	}
	if(url.split('dam/storage/duplicates').length > 1) {
		$('.dam-duplicates').addClass('active');
	}
	if(url.split('dam/storage/upload').length > 1) {
		$('.dam-upload').addClass('active');
	}
	if(url.split('dam/folder').length > 1 || url.split('dam/tag').length > 1)  {
		$('.dam-admin').addClass('active');
	}
	if(url.split('dam/history/share').length > 1) {
		$('.dam-history').addClass('active');
	}

	if(url.split('/pim/product/inventory/list').length > 1) {
		$('.pim-products').addClass('active');
	}
	if(url.split('/pim/export/product').length > 1 || url.split('/pim/export/template/list').length > 1) {
		$('.pim-export').addClass('active');
	}
	if(url.split('/pim/category/collection/list').length > 1 || url.split('/pim/tag/collection/list').length > 1) {
		$('.pim-admin').addClass('active');
	}
	if(url.split('/pim/import/product').length > 1 || url.split('/pim/import/template/list').length > 1 || url.split('/pim/sync/databridge').length > 1) {
		$('.pim-setup').addClass('active');
	}

	if(url.split('/acl/user/list').length > 1) {
		$('.acl-users').addClass('active');
	}
	if(url.split('/acl/tag-folder').length > 1 || url.split('/acl/tag-category').length > 1) {
		$('.acl-access').addClass('active');
	}

	if(url.split('/support#faq').length > 1) {
		$('.support-faq').addClass('active');
	}
	if(url.split('/support#terms').length > 1) {
		$('.support-terms').addClass('active');
	}
	if(url.split('/support#privacy').length > 1) {
		$('.support-privacy').addClass('active');
	}
	if(url.split('/support/contact').length > 1) {
		$('.support-contact').addClass('active');
	}
	$('#faq_link').click(function(){
		$(".faq").show();
		$(".terms").hide();
		$(".privacy").hide();
		$("#faq_link").addClass('active');
		$("#privacy_link").removeClass('active');
		$("#terms_link").removeClass('active');
		$('body').scrollTop(0);
	});
	$('#terms_link').click(function(){
		$(".terms").show();
		$(".faq").hide();
		$(".privacy").hide();
		$("#terms_link").addClass('active');
		$("#faq_link").removeClass('active');
		$("#privacy_link").removeClass('active');
		$('body').scrollTop(0);
	});
	$('#privacy_link').click(function(){
		$(".privacy").show();
		$(".faq").hide();
		$(".terms").hide();
		$("#privacy_link").addClass('active');
		$("#faq_link").removeClass('active');
		$("#terms_link").removeClass('active');
		$('body').scrollTop(0);
	});
	$('#privacy_link').click(function(){
		$('body').scrollTop(0);
	});

	var url = window.location.href;
	if(url.split('#faq').length > 1) {
		$(".terms").hide();
		$(".privacy").hide();
		$('body').scrollTop(0);
	}
	if(url.split('#terms').length > 1) {
		$(".faq").hide();
		$(".privacy").hide();
		$('body').scrollTop(0);
	}
	if(url.split('#privacy').length > 1) {
		$(".faq").hide();
		$(".terms").hide();
		$('body').scrollTop(0);
	}
});

jQuery(function($) {
	$('#customer-tag-search').keyup(function(){
		var valThis = $(this).val().toLowerCase();
		if(valThis == ""){
			$('#tag-container-customer > div').show();
		} else {
			$('#tag-container-customer > div').each(function(){
				var text = $(this).text().toLowerCase();
				(text.indexOf(valThis) >= 0) ? $(this).show() : $(this).hide();
			});
		}
	});
});

(function($) {
	$.fn.onEnter = function(func) {
		this.bind('keypress', function(e) {
			if (e.keyCode == 13) func.apply(this, [e]);
		});
		return this;
	};
})(jQuery);

$('.modal').on('shown.bs.modal', function() {
	$(this).find('[autofocus]').focus();
});

(function ($) {
	$.each(['show', 'hide'], function (i, ev) {
		var el = $.fn[ev];
		$.fn[ev] = function () {
			this.trigger(ev);
			return el.apply(this, arguments);
		};
	});
})(jQuery);

if ('serviceWorker' in navigator) {
	// Register a service worker hosted at the root of the
	// site using the default scope.
	navigator.serviceWorker.register('/dist/sw.js').then(function(registration) {
		
	}, /*catch*/ function(error) {
		
	});
} else {
	
}


// Function to check all checkboxes with an identifer
function checkAll(id) {
  var checkboxes = document.querySelectorAll('#' + id + ' input[type="checkbox"]');
  for (var i = 0; i < checkboxes.length; i++) {
    checkboxes[i].checked = true;
  }
}

// Function to uncheck all checkboxes with an identifer
function uncheckAll(id) {
  var checkboxes = document.querySelectorAll('#' + id + ' input[type="checkbox"]');
  for (var i = 0; i < checkboxes.length; i++) {
    checkboxes[i].checked = false;
  }
}

// Function to invert/toggle the current selection of checkboxes with an identifer
function toggleCheckboxes(id) {
  var checkboxes = document.querySelectorAll('#' + id + ' input[type="checkbox"]');
  for (var i = 0; i < checkboxes.length; i++) {
    checkboxes[i].checked = !checkboxes[i].checked;
  }
}